import type FixedSlider from '@Component/Slider/FixedSlider';
import type {SliderOptions} from '@Component/Slider/Types/SliderOptions';

export default class ResponsiveSlider {
    private readonly slider: FixedSlider;
    private options: SliderOptions;
    private activeBreakpoint: number;

    constructor(slider: FixedSlider, options: SliderOptions) {
        this.slider = slider;
        this.options = options;
        this.activeBreakpoint = 0;

        this.options.breakpoints.sort((current, next) => next.minSliderWidth - current.minSliderWidth);
        this.listen();
        this.checkBreakpoint();
    }

    public update(): void {
        this.checkBreakpoint();
    }

    private listen(): void {
        this.slider.addEventListener('mounted', () => this.checkBreakpoint());
        window.addEventListener('resize', () => this.checkBreakpoint());
    }

    private checkBreakpoint(): void {
        const config = this.options.breakpoints.find((option) => this.slider.getWidth() >= option.minSliderWidth);
        if (config === undefined || config.minSliderWidth === this.activeBreakpoint) {
            return;
        }

        this.slider.setOptions(config.sliderProperties);
        this.activeBreakpoint = config.minSliderWidth;
    }
}
