import component from '@123/druid/dist/Framework/Decorators/Component';
import View from '@123/druid/dist/Framework/View/View';
import type FixedSlider from '@Component/Slider/FixedSlider';
import ResponsiveSlider from '@Component/Slider/ResponsiveSlider';
import type {SliderOptions} from '@Component/Slider/Types/SliderOptions';

@component
export default class NewsCarouselView extends View {
    private slider?: FixedSlider;

    private options: SliderOptions = {
        breakpoints: [
            {
                minSliderWidth: 320,
                sliderProperties: {
                    slidesVisible: 1,
                    slidesPerPage: 1,
                },
            },
            {
                minSliderWidth: 380,
                sliderProperties: {
                    slidesVisible: 2,
                    slidesPerPage: 1,
                },
            },
            {
                minSliderWidth: 500,
                sliderProperties: {
                    slidesVisible: 3,
                    slidesPerPage: 1,
                },
            },
            {
                minSliderWidth: 768,
                sliderProperties: {
                    slidesVisible: 4,
                    slidesPerPage: 1,
                },
            },
        ],
    };

    protected mounted(): void {
        void this.el.queryRolePromise<FixedSlider>('slider').then((element) => {
            this.slider = element;
            void new ResponsiveSlider(this.slider, this.options);
        });
    }
}
